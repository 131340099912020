import * as React from 'react'
import { WrappedFieldProps } from 'redux-form'
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core'

interface SelectorProps extends WrappedFieldProps {
  label: string,
  values: Entity[],
  disabled: boolean
}

export default class Selector extends React.Component<SelectorProps> {
  handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.input.onChange(e.target.value)
  }

  render() {
    const { input, meta, disabled } = this.props
    return (
      <FormControl className="selector__organizations" disabled={disabled}>
        <InputLabel>Организация</InputLabel>
        <Select
          value={input.value}
          onChange={this.handleChange}
          error={!!meta.error}
        >
          {this.props.values.map(entity => (
            <MenuItem value={entity.id}>{entity.name}</MenuItem>
          ))}
        </Select>
        {meta.error && <p className="md__error">{meta.error}</p>}
      </FormControl>
    )
  }
}
