import * as React from 'react'

export function wrapForReduxForm<T>(Component: React.ComponentType<T>): React.ComponentType<T> {
  return ({input, meta, ...otherProps}: any) => (
    <Component
      {...input}
      error={meta.error}
      {...otherProps}
    />
  )
}
