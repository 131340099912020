import * as React from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { RouteComponentProps, withRouter } from 'react-router'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core'
import { ModalTypes } from '../constants'
import { showModal } from '../actions/uiActions'
import './style.css'

type IUsersTableProps = RouteComponentProps & {
  className: string,
  history?: History,
  self: User,
  users: User[],
  organizations: Entity[],
  openUserDetails: (user: User) => void
}

const userRowModificator = (user: User) => {
    if (user.isService) return 'service'
    if (!user.isFilled) return 'invalid'
    return ''
}

class UsersTable extends React.Component<IUsersTableProps> {

  openDetailsModal = (user: User) => {
      this.props.history.push(`/users/${user._id}`)
      this.props.openUserDetails(user)
  }

  renderRow = (orgData: {}, user: User) => (
      <TableRow
          key={user._id}
          className={`user-table__row ${userRowModificator(user)}`}
          onClick={this.openDetailsModal.bind(this, user)}
      >
          <TableCell>{user.fullName}</TableCell>
          <TableCell>{user.email}</TableCell>
          <TableCell>{user.phone || <img src="/alert.png" />}</TableCell>
          <TableCell>{
              (
                  user.birthdate &&
                  user.birthdate
                      .split('.')
                      .slice(0, 2)
                      .join('.')
              ) || <img src="/alert.png" />}</TableCell>
          <TableCell>{user.companyId ? orgData[user.companyId] : <img src="/alert.png" />}</TableCell>
          <TableCell>{user.position || <img src="/alert.png" />}</TableCell>
      </TableRow>
  )

  render() {
      const { self, className, users, organizations } = this.props
      const orgData = organizations
          .reduce((prev, curr) => ({
              ...prev,
              [curr.id]: curr.name
          }), {})

      const accounts = users.filter(x => !x.isService).map(this.renderRow.bind(this, orgData))
      const serviceAccounts = users.filter(x => x.isService).map(this.renderRow.bind(this, orgData))

      return (
          <div className={className}>
              <Table>
                  <TableHead>
                      <TableRow>
                          <TableCell style={{ width: '25%' }}>Имя</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell style={{ width: '120px' }}>Телефон</TableCell>
                          <TableCell>Дата рождения</TableCell>
                          <TableCell>Компания</TableCell>
                          <TableCell>Должность</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {accounts}
                      {(self.isAdmin || self.isModerator) ? serviceAccounts : null}
                  </TableBody>
              </Table>
              {accounts.length + serviceAccounts.length === 0 && (
                  <p className="noResult">Никого не найдено</p>
              )}
          </div>
      )
  }
}

export default connect(createSelector(
    [
        (state: State) => state.users.self,
        (state: State) => state.users.data,
        (state: State) => state.books.organizations
    ],
    (self, users, organizations) => ({ self, users, organizations })
), dispatch => ({
    openUserDetails: (user: User) => dispatch(showModal(ModalTypes.UserDetails, user))
}))(withRouter(UsersTable))
