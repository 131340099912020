import * as React from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { reduxForm, Field, InjectedFormProps } from 'redux-form'
import { Input, Switch } from '@material-ui/core'
import MaterialSelect from './MateralSelect'
import { wrapForReduxForm } from '../helpers/ReduxFormHelper'

const WrappedInput: any = wrapForReduxForm(Input)
const WrappedSwitch: any = wrapForReduxForm(
  ({ value, ...props }: any) => <Switch {...props} value={String(value)} />
)

const WrappedMaterialSelect: any = wrapForReduxForm(
  ({ value = null, onBlur, ...props }: any) =>
    <MaterialSelect {...props} value={value} onBlur={() => onBlur(value)} />
)

interface SearchProps extends InjectedFormProps {
  isAdmin: boolean,
  organizations: any
}

class Search extends React.Component<SearchProps> {
  state = {
    options: this.props
      .organizations
      .map((x: Entity) => ({ value: x.id, label: x.name }))
  }

  componentDidUpdate() {
    if (this.props.organizations.length !== this.state.options.length) {
      this.setState({
        options: this.props
          .organizations
          .map((x: Entity) => ({ value: x.id, label: x.name }))
      })
    }
  }

  render() {
    const { isAdmin } = this.props
    return (
      <div className="search__wrapper">
        <Field
          name="query"
          className="searchInput"
          placeholder="Поиск пользователей..."
          autoFocus={true}
          component={WrappedInput}
        />
        <div className="search__organization">
          <Field
            name="companyId"
            options={this.state.options}
            placeholder="Введите название организации"
            component={WrappedMaterialSelect}
          />
        </div>
        {isAdmin && (
          <div className="search__wrapper">
            <span className="search__invalid-label">Незаполненные</span>
            <Field
              name="invalid"
              component={WrappedSwitch}
            />
          </div>
        )}
      </div>
    )
  }
}

const SearchForm: any = reduxForm({
  form: 'search'
})(Search as unknown as any)

const ConnectedSearchForm: any = connect(createSelector(
  [(state: State) => state.books.organizations],
  organizations => ({ organizations })
))(SearchForm)

export default ConnectedSearchForm
