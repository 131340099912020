import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import {Router} from 'react-router-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import store from './store/store'
import App from './App'
import './index.css'
import { createBrowserHistory } from "history"

export const browserHistory = createBrowserHistory()

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#f6f9fc'
    }
  }
})

ReactDOM.render((
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <Router history={browserHistory}>
          <App/>
        </Router>
      </MuiThemeProvider>
    </Provider>
), document.getElementById('root') as HTMLElement)
