import * as React from 'react'
import Alert from 'react-s-alert'
import { wrapForReduxForm } from '../../helpers/ReduxFormHelper'
import { WrappedFieldProps } from 'redux-form'

class AvatarInput extends React.Component<WrappedFieldProps & {
  value: string,
  error: string,
  disabled: boolean,
  onError: () => void,
  onChange: (value: any) => void,
  onChangeValue: (value: string) => void,
}> {
  shouldComponentUpdate(nextProps: any) {
    return this.props.value !== nextProps.value || this.props.error !== nextProps.error
  }

  input: HTMLInputElement | null = null

  getBase64 = (file: any, cb: any) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => cb(reader.result)
  }

  onChooseImage = ({ target: { files } }: any) => {
    const file = files[0]
    if (file.size > 2000000) {
      Alert.warning('Размер фото не должен превышать 2мб')
      return
    }

    this.getBase64(file, (result: string) => {
      this.props.onChange(result)
      this.props.onChangeValue(result)
    })
  }

  render() {
    const { onError, value, error } = this.props
    return (
      <div>
        <a href="https://aboutme.google.com/" target="_blank">
          <img onError={onError} src={value || '/noavatar.jpg'}/>
        </a>
        <input
          onChange={this.onChooseImage}
          ref={ref => this.input = ref}
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
        />
        {error && <p className="md__error">{error}</p>}
      </div>
    )
  }
}

export default wrapForReduxForm(AvatarInput)
