import * as React from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { withRouter } from 'react-router-dom'
import { Modal } from '@material-ui/core'
import * as ui from '../actions/uiActions'
import { fetchUser } from '../actions/usersActions'
import { ModalTypes } from '../constants'
import UserDetailsModal from './userDetailsModal/UserDetailsModal'
import './style.css'

type IModalRootProps = {
  location?: Location,
  history?: History,
  modalType?: ModalTypes,
  data: any,
  fetchUser: () => void,
  closeModal: () => void
} & any

class RootModal extends React.Component<IModalRootProps> {

  componentDidMount() {
    const { location } = window
    const hashArr = location.pathname.split('/')
    if (hashArr.length === 3 && hashArr[1] === 'users') {
      this.props.fetchUser(hashArr[2])
    }
  }

  closeModal = () => {
    this.props.closeModal()
    this.props.history.replace('/')
  }

  render() {
    const { modalType: type } = this.props

    return (
      <Modal className="modal" open={!!type} onClose={this.closeModal}>
        <div className="modal">
          <div className="modal__content">
            {type === ModalTypes.UserDetails && <UserDetailsModal {...this.props}/>}
          </div>
        </div>
      </Modal>
    )
  }
}

export default connect(createSelector(
  [(state: State) => state.ui],
  ({ modal, data }) => ({ modalType: modal, data })
), dispatch => ({
  fetchUser: (userId: string) => dispatch(fetchUser(userId)),
  closeModal: () => dispatch(ui.closeModal())
}))(withRouter(RootModal))
