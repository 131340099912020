import { all, fork } from 'redux-saga/effects'
import usersSaga from './usersSaga'
import booksSaga from './booksSaga'

export default function* () {
  yield all([
    fork(usersSaga),
    fork(booksSaga)
  ])
}
