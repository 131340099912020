import { ModalTypes } from '../constants'

export const TOGGLE_MODAL_ACTION = 'ui.TOGGLE_MODAL_ACTION'

export const showModal = (modalType: ModalTypes, data: any) => ({
  type: TOGGLE_MODAL_ACTION,
  payload: { type: modalType, data }
})

export const closeModal = () => ({
  type: TOGGLE_MODAL_ACTION,
  payload: null
})
