const BASE_URL = '/api/v1'

const headers = { 'Content-Type': 'application/json' }

export default class API {
  static async fetchSelf(): Promise<any> {
    const response = await fetch(`${BASE_URL}/user/me`, { credentials: 'include' })

    if (response.status === 401) {
      return { error: 'unauthorized' }
    }

    return response.json()
  }

  static async searchUsers(query?: string): Promise<any> {
    const response = await fetch(`${BASE_URL}/search?${query}`, {
      credentials: 'include'
    })

    return response.json()
  }

  static async fetchUser(userId: String): Promise<User> {
    const response = await fetch(`${BASE_URL}/users/${userId}`, { credentials: 'include'  })
    return response.json()
  }

  static async updateUser(userId: string, updates: Object): Promise<any> {
    const response = await fetch(`${BASE_URL}/user/${userId}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(updates),
      credentials: 'include'
    })

    if (response.status !== 200) {
      throw new Error()
    }

    return response
  }

  static async fetchOrganizations(): Promise<any> {
    const response = await fetch(`${BASE_URL}/organizations`, { credentials: 'include'  })
    return response.json()
  }
}
