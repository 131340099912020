import * as actions from '../actions/usersActions'

interface IUsersReducerState {
  self: User | null,
  data: User[],
  updating: string | null
}

const initialState: IUsersReducerState = {
  self: null,
  data: [],
  updating: null
}

export default (state: IUsersReducerState = initialState, action: Action<any>): IUsersReducerState => {
  switch (action.type) {
    case actions.FETCH_SELF_SUCCESS:
      return { ...state, self: action.payload }
    case actions.SEARCH_USERS_SUCCESS:
      return { ...state, data: action.payload }
    case actions.UPDATE_USER_REQUEST:
      return { ...state, updating: 'process' }
    case actions.UPDATE_USER_SUCCESS:
      return { ...state, updating: 'success' }
    case actions.UPDATE_USER_FAILED:
      return { ...state, updating: 'failed' }
    default:
      return state
  }
}
