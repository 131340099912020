import * as React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import Main from './main'

import Login from './components/Login'

class App extends React.Component {
  render() {
    return (
      <Switch>
        <Route
          exact={true}
          path="/login"
          component={Login}
        />
        <Route
          path="/"
          component={Main}
        />
      </Switch>
    )
  }
}

// @ts-ignore
export default withRouter(App)
