import * as React from 'react'
import TextField from '@material-ui/core/TextField'
import InputMask from 'react-input-mask'
import { wrapForReduxForm } from '../../helpers/ReduxFormHelper'
import { WrappedFieldProps } from 'redux-form'

class TextInput extends React.Component<WrappedFieldProps & {
  label: string,
  mask: string,
  error?: string,
  disabled: boolean
}> {
  render() {
    const { disabled, mask, error } = this.props
    return !disabled && mask ? (
      <InputMask {...this.props} mask={mask}>
        {(props: any) => (
          <TextField
            {...props}
            error={!!error}
            helperText={error}
          />
        )}
      </InputMask>
    ) : (
      <TextField
        {...this.props}
        error={!!error}
        helperText={error}
      />
    )
  }
}

export default wrapForReduxForm(TextInput)
