import * as React from 'react'
import {FormHelperText, Button} from "@material-ui/core"
import * as queryString from 'query-string'
import googleSvg from './google.svg'
import brusnikaSvg from './brusnika.svg'
import "./styles.css"

interface ILoginState {
  error: string
}

class Login extends React.Component<any, ILoginState> {
    constructor(props: any) {
        super(props)

        const parsed = queryString.parse(window.location.search)

        const error = (Array.isArray(parsed.error) || parsed.error == null)  ? '' : parsed.error

        this.state = {
            error
        }
    }

    getErrorText(err: string) {
        if (err === 'wrong_email_domain') {
            return 'Доступ запрещен!<br/>' +
        'Вы пытаетесь подключиться к серверу используя почту не входящую в корпоративный домен.<br/>' +
        'Авторизуйтесь используя корпоративную почту или обратитесь к администратору.'
        }

        return err ? 'Произошла ошибка авторизации,<br/> попробуйте еще раз' : ''
    }

    redirectToServerLogin() {
        window.location.href = '/api/v1/login'
    }

    render() {
        const { error } = this.state

        return (
            <div className="root">
                <div className="form">
                    <div className="title">
                        <img
                            alt="Брусника"
                            src={brusnikaSvg}
                            className="brusnikaIcon"
                        />
                        <h2>
                            Вход в Брусника Контакты
                        </h2>
                    </div>
                    <div className="actions">
                        <Button
                            variant="raised"
                            className="button"
                            fullWidth={true}
                            onClick={this.redirectToServerLogin}
                        >
                            <img
                                alt="google icon"
                                className="googleIcon"
                                src={googleSvg}
                            />
                            &nbsp;
                            <span>Войти с помощью Google</span>
                        </Button>
                        <FormHelperText
                            error
                            className="error"
                            dangerouslySetInnerHTML={{
                                __html: this.getErrorText(error)
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default Login
