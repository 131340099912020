import { call, put, takeLatest } from 'redux-saga/effects'
import * as actions from '../actions/booksActions'
import API from '../api'

function* fetchOrganizations(): any {
  try {
    const response = yield call(API.fetchOrganizations)
    yield put({ type: actions.FETCH_ORGANIZATIONS_SUCCESS, payload: response })
  } catch (e) {
  }
}

export default function* () {
  yield takeLatest(actions.FETCH_ORGANIZATIONS_REQUEST, fetchOrganizations)
}
