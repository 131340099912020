import * as actions from '../actions/uiActions'
import { ModalTypes } from '../constants'

interface IUIReducerState {
  modal: ModalTypes | null,
  data?: any
}

const initialState: IUIReducerState = {
  modal: null,
  data: null
}

export default (state: IUIReducerState = initialState, action: Action<any>): IUIReducerState => {
  switch (action.type) {
    case actions.TOGGLE_MODAL_ACTION:
      return {
        ...state,
        modal: action.payload ? action.payload.type : null,
        data: action.payload ? action.payload.data : null
      }
    default:
      return state
  }
}
