import * as React from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { reduxForm, Field, change } from 'redux-form'
import Alert from 'react-s-alert'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Button, LinearProgress, Switch, Checkbox, FormControlLabel } from '@material-ui/core'
import { wrapForReduxForm } from '../../helpers/ReduxFormHelper'
import { updateUser } from '../../actions/usersActions'
import TextInput from './TextInput'
import AvatarInput from './AvatarInput'
import Selector from './Selector'
import validator from './validator'
import './style.css'

interface IUserDetailsModalProps {
  data: User,
  self: User,
  organizations: Entity[],
  form: any,
  requestStatus: string | null,
  closeModal: () => void,
  dispatch: Function,
  handleSubmit: Function,
  initialValues: { middleName: string },
  valid: Boolean
}

const FORM_KEY = 'userEdit'

const WrappedSwitch: any = wrapForReduxForm(
    ({ value, ...props }: any) => <Switch {...props} checked={value} />
)

const WrappedCheckbox: any = wrapForReduxForm(
    ({ value, ...props }: any) => <Checkbox {...props} checked={value} />
)

const phoneValidator = (value: string, values: any) => {
    if (values.isService) return
    return !value || !value.includes('_') ? undefined : 'Введите полный номер'
}

const notEmptyValidator = (value: string = '', values: any) => {
    if (values.isService) return
    if (value.length === 0) return 'Необходимо заполнить поле'
    return
}

const birthdayValidator = (value: string = '', values: any, ...args: Array<any>) => {
    if (values.isService) return

    if (value.length === 0) return 'Введите дату рождения'
    const dateArray = value.split('.')
    return !value.includes('_') && dateArray.length === 3 && parseInt(dateArray[0]) < 32 && parseInt(dateArray[1]) < 13 && dateArray[2].length === 4 ? undefined : 'Введите дату рождения'
}

const employmentDateValidator = (value: string = '', values: any, ...args: Array<any>) => {
    if (values.isService) return
    const exceptionText = 'Введите дату приема на работу'
    if (value.length === 0) return exceptionText
    const dateArray = value.split('.')
    return !value.includes('_') && dateArray.length === 3 && parseInt(dateArray[0]) < 32 && parseInt(dateArray[1]) < 13 && dateArray[2].length === 4 ? undefined : 'Введите дату приема на работу'
}

const dismissalDateValidator = (value: string = '', values: any, ...args: Array<any>) => {
    if (values.isService) return
    const exceptionText = 'Введите дату увольнения'
    if (value.length === 0) return exceptionText
    const dateArray = value.split('.')
    return !value.includes('_') && dateArray.length === 3 && parseInt(dateArray[0]) < 32 && parseInt(dateArray[1]) < 13 && dateArray[2].length === 4 ? undefined : exceptionText
}

class UserDetailsModal extends React.Component<IUserDetailsModalProps> {
  onSubmit = (values: Object) => {
      this.props.dispatch(updateUser(this.props.data._id, values))
  }

  onErrorAvatarLoad = () => {
      this.props.dispatch(change(FORM_KEY, 'avatar', ''))
  }

  onChangeAvatar = (value: string) => {
      this.props.dispatch(change(FORM_KEY, 'avatarData', value))
  }

  mapUserToLabel = (user: User): string | null => {
      if (user.isAdmin) return 'администратор'
      if (user.isModerator) return 'модератор'
      return null
  }

  render() {
      const { data: user, self, organizations, requestStatus, handleSubmit, initialValues, valid, form: { fields } } = this.props
      const isLoading = requestStatus === 'process'
      const isCanEdit = self.isModerator || self.isAdmin || user._id === self._id
      const userLabel = this.mapUserToLabel(user)
      const isBirthdateFocused = fields && fields.birthdate && fields.birthdate.active

      return (
          <div className="md__root">
              <div className="md__head">
                  <Field
                      name="avatar"
                      onError={this.onErrorAvatarLoad}
                      onChangeValue={this.onChangeAvatar}
                      component={AvatarInput}
                      disabled={!isCanEdit}
                  />
                  <div style={{ flex: 1 }}>
                      <h1>{user.fullName}</h1>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                          {userLabel && (
                              <span className="md__user-label">{userLabel}</span>
                          )}
                          <CopyToClipboard
                              text={window.location.href}
                              onCopy={() => Alert.success('Ссылка на контакт скопирована')}
                          >
                              <span className="md__copy-label">ссылка на контакт</span>
                          </CopyToClipboard>
                      </div>
                  </div>
                  {isCanEdit && (
                      <div>
                          <Button
                              onClick={handleSubmit(this.onSubmit)}
                              className="md__button"
                              disabled={isLoading || (user.isService ? false : !valid)}
                          >
                              Сохранить
                          </Button>
                          {(self.isAdmin || self.isModerator) && (
                              <div>
                                  <span className="md__moderator-label">Модератор</span>
                                  <Field
                                      name="isModerator"
                                      component={WrappedSwitch}
                                  />
                              </div>
                          )}
                      </div>
                  )}
              </div>
              {isLoading && <LinearProgress color="secondary"/>}
              <div className="md__body">
                  {(self.isAdmin || self.isModerator) && (
                     <>
                         <div className="md__service">
                              <FormControlLabel
                                  control={
                                      <Field
                                          name="isSuspended"
                                          disabled
                                          component={WrappedCheckbox}
                                      />
                                  }
                                  label="Приостановлен"
                              />
                          </div>   
                          <div className="md__service">
                              <FormControlLabel
                                  control={
                                      <Field
                                          name="isService"
                                          component={WrappedCheckbox}
                                      />
                                  }
                                  label="Технический аккаунт"
                              />
                          </div>
                      </>
                  )}
                  <div className="md__form">
                      <div className="md__inline">
                          <Field
                              name="givenName"
                              label="Имя"
                              disabled={true}
                              component={TextInput}
                          />
                          {initialValues.middleName.length > 0 ?
                              <Field
                                  name="middleName"
                                  label="Отчество"
                                  disabled={true}
                                  component={TextInput}
                              /> :
                              null
                          }
                          <Field
                              name="familyName"
                              label="Фамилия"
                              disabled={true}
                              component={TextInput}
                          />
                      </div>
                  </div>
                  <div className="md__form">
                      <Field
                          name="email"
                          label="Email"
                          component={TextInput}
                          disabled={true}
                          className="md__text-field"
                      />
                  </div>
                  <div className="md__form">
                      <Field
                          name="birthdate"
                          label="Дата рождения"
                          mask={isBirthdateFocused ? "99.99.9999" : "99.99"}
                          validate={birthdayValidator}
                          component={TextInput}
                          disabled={!isCanEdit}
                          className="md__text-field"
                      />
                  </div>
                  <div className="md__form">
                      <Field
                          name="phone"
                          label="Телефон"
                          mask="+7 (999) 999 99 99"
                          validate={phoneValidator}
                          component={TextInput}
                          disabled={!isCanEdit}
                          className="md__text-field"
                      />
                  </div>
                  <div className="md__form">
                      <Field
                          name="companyId"
                          label="Компания"
                          validate={notEmptyValidator}
                          values={organizations}
                          className="md__text-field"
                          disabled={!isCanEdit}
                          component={Selector}
                      />
                      <Field
                          name="position"
                          label="Должность"
                          className="md__text-field"
                          disabled={!isCanEdit}
                          component={TextInput}
                      />
                      <Field
                          name="workplace"
                          label="Место работы"
                          className="md__text-field"
                          disabled={!isCanEdit}
                          component={TextInput}
                      />
                  </div>
                  {((self.isAdmin || self.isModerator) && (
                    <div className="md__form">
                      <Field
                          name="dateOfEmployment"
                          label="Дата приема на работу"
                          mask={"99.99.9999"}
                          validate={employmentDateValidator}
                          component={TextInput}
                          disabled={!isCanEdit}
                          className="md__text-field"
                      />
                      <Field
                          name="dateOfDismissal"
                          label="Дата увольнения"
                          mask={"99.99.9999"}
                          validate={dismissalDateValidator}
                          component={TextInput}
                          disabled={!isCanEdit}
                          className="md__text-field"
                      />
                    </div>))}
              </div>
          </div>
      )
  }
}

const ConnectedUserDetailsModal: any = connect(createSelector(
    [
        (state: State) => state.users.updating,
        (state: State) => state.users.self,
        (state: State) => state.books.organizations,
        (state: State) => state.form[FORM_KEY],
    ],
    (requestStatus, self, organizations, form) => ({ requestStatus, self, organizations, form })
))(UserDetailsModal)

const ReduxForm: any = reduxForm({
    form: FORM_KEY,
    validate: validator
})(ConnectedUserDetailsModal)

export default class WrappedReduxForm extends React.PureComponent<{
  data: User,
  self: User,
  requestStatus: Function,
  closeModal: Function
} & any> {
    render() {
        const { data: user, self } = this.props

        const givenNameSplitted = user.givenName.split(' ')
        let firstName = user.givenName
        let middleName = ""

        if (givenNameSplitted.length == 2) {
            firstName = givenNameSplitted[0]
            middleName = givenNameSplitted[1]
        }

        // const adminFields = (self.isAdmin || self.isModerator) ?
        //     {}
        return (
            <ReduxForm
                data={user}
                self={self}
                requestStatus={this.props.requestStatus}
                closeModal={this.props.closeModal}
                initialValues={{
                    givenName: firstName,
                    familyName: user.familyName,
                    middleName: middleName,
                    birthdate: user.birthdate,
                    avatar: `/api/v1/avatar/${user._id}`,
                    companyId: user.companyId,
                    position: user.position,
                    workplace: user.workplace,
                    email: user.email,
                    phone: user.phone,
                    isModerator: user.isModerator,
                    isService: user.isService,
                    dateOfDismissal: user.dateOfDismissal || "00.00.0000",
                    dateOfEmployment: user.dateOfEmployment || "00.00.0000"
                }}
            />
        )
    }
}
