import { select, call, put, throttle, takeLatest } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import { formValueSelector } from 'redux-form'
import Alert from 'react-s-alert'
import * as queryString from 'querystring'
import { closeModal, showModal } from '../actions/uiActions'
import { FETCH_ORGANIZATIONS_REQUEST } from '../actions/booksActions'
import * as actions from '../actions/usersActions'
import API from '../api'
import { ModalTypes } from '../constants'
import {browserHistory} from "../index";

const searchFormSelector = (state: State) => formValueSelector('search')(state, 'query', 'invalid', 'companyId')

function* fetchSelf(): any {
  try {
    const response = yield call(API.fetchSelf)

    if (response.error) {
      return browserHistory.push('/login')
    }

    yield put({ type: FETCH_ORGANIZATIONS_REQUEST })
    yield put({ type: actions.FETCH_SELF_SUCCESS, payload: response })
  } catch (e) {
  }
}

function* fetchUsers(): any {
  try {
    const { query, invalid, companyId } = yield select(searchFormSelector)
    const str = queryString.stringify({ q: query, invalid, companyId })
    const response = yield call(API.searchUsers, str)
    yield put({ type: actions.SEARCH_USERS_SUCCESS, payload: response })
  } catch (e) {
  }
}

function* fetchUser(action: Action<string>) {
  try {
    const user: User = yield call(API.fetchUser, action.payload)

    console.log('FETCHED USER:', user)

    yield put(showModal(ModalTypes.UserDetails, user))
  } catch (e) {
  }
}

function* updateUser(action: Action<Object>) {
  try {
    const { userId, updates: { isSkypeNotExist, ...data } }: any = action.payload
    yield call(API.updateUser, userId, data)
    yield put({ type: actions.UPDATE_USER_SUCCESS })
    Alert.success('Изменения успешно сохранены')

    yield put(closeModal())
    yield put(actions.fetchSelf())

    yield call(delay, 2000)
    yield put(actions.searchUsers())
  } catch (e) {
    yield put({ type: actions.UPDATE_USER_FAILED })
    Alert.error('Не удалось сохранить информацию')
  }
}

export default function* () {
  const takeSearchHandler = ({ type, meta }: any) =>
    type === actions.SEARCH_USERS_REQUEST || (
      type === '@@redux-form/CHANGE' &&
      meta.form === 'search'
    )

  yield throttle(750, takeSearchHandler, fetchUsers)
  yield takeLatest(actions.UPDATE_USER_REQUEST, updateUser)
  yield takeLatest(actions.FETCH_SELF_REQUEST, fetchSelf)
  yield takeLatest(actions.FETCH_USER_REQUEST, fetchUser)
}
