import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import reduxLogger from 'redux-logger'
import rootReducer from '../reducers/rootReducer'
import rootSaga from '../sagas/rootSaga'

const sagaMiddleware = createSagaMiddleware()
const store = createStore(rootReducer, process.env.NODE_ENV !== 'production'
  ? applyMiddleware(sagaMiddleware, reduxLogger)
  : applyMiddleware(sagaMiddleware)
)

sagaMiddleware.run(rootSaga)

export default store
