import * as React from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import Alert from 'react-s-alert'
import { AppBar, Card, Button, LinearProgress } from '@material-ui/core'
import { fetchSelf } from '../actions/usersActions'
import { showModal } from '../actions/uiActions'
import { ModalTypes } from '../constants'
import RootModal from '../modals/RootModal'
import UsersTable from './UsersTable'
import Search from './Search'
import 'react-s-alert/dist/s-alert-default.css'
import 'react-s-alert/dist/s-alert-css-effects/slide.css'
import './style.css'

interface MainProps {
  self: User | null
  fetchSelf: () => void,
  openProfile: (user: User) => void
}

class Main extends React.Component<MainProps> {
  componentDidMount() {
    this.props.fetchSelf()
  }

  componentDidUpdate() {
    const { self } = this.props
    if (self && !self.isFilled) {
      // this.onProfileClick()
    }
  }

  onProfileClick = () => {
    if (this.props.self) {
      this.props.openProfile(this.props.self)
    }
  }

  render() {
    const { self } = this.props
    if (!self) return <LinearProgress/>

    return (
      <div className="main">
        <AppBar className="appBar" position="static">
          <div className="name">
            <img src="/logo.png"/>
            <h1><span style={{ color: '#e83333' }}>Брусника</span> <span style={{ color: '#b5b5b5' }}>Контакты</span></h1>
          </div>
          <Button onClick={this.onProfileClick}>Мой профиль</Button>
        </AppBar>
        <Card className="content">
          <Search isAdmin={self.isModerator || self.isAdmin} />
          <UsersTable className="tableUsers"/>
        </Card>
        <RootModal/>
        <Alert stack={{ limit: 1 }} />
      </div>
    )
  }
}

export default connect(createSelector(
  [(state: State) => state.users.self],
  self => ({ self })
), dispatch => ({
  fetchSelf: () => dispatch(fetchSelf()),
  openProfile: (user: User) => dispatch(showModal(ModalTypes.UserDetails, user))
}))(Main)
