import { combineReducers } from 'redux'
import { reducer as reduxFormReducer } from 'redux-form'
import usersReducer from './usersReducer'
import booksReducer from './booksReducer'
import uiReducer from './uiReducer'

export default combineReducers({
  form: reduxFormReducer,
  users: usersReducer,
  books: booksReducer,
  ui: uiReducer
})
