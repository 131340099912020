export const FETCH_SELF_REQUEST = 'users.FETCH_SELF_REQUEST'
export const FETCH_SELF_SUCCESS = 'users.FETCH_SELF_SUCCESS'

export const SEARCH_USERS_REQUEST = 'users.SEARCH_USERS_REQUEST'
export const SEARCH_USERS_SUCCESS = 'users.SEARCH_USERS_SUCCESS'

export const FETCH_USER_REQUEST = 'users.FETCH_USER_REQUEST'
export const FETCH_USER_SUCCESS = 'users.FETCH_USER_SUCCESS'
export const FETCH_USER_FAILED = 'users.FETCH_USER_FAILED'

export const UPDATE_USER_REQUEST = 'users.UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'users.UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILED = 'users.UPDATE_USER_FAILED'

export const fetchSelf = () => ({
  type: FETCH_SELF_REQUEST
})

export const searchUsers = () => ({
  type: SEARCH_USERS_REQUEST
})

export const fetchUser = (userId: string) => ({
  type: FETCH_USER_REQUEST,
  payload: userId
})

export const updateUser = (userId: string, updates: Object) => ({
  type: UPDATE_USER_REQUEST,
  payload: { userId, updates }
})

