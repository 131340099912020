import * as actions from '../actions/booksActions'

interface BooksReducerState {
  organizations: Entity[]
}

const initialState: BooksReducerState = {
  organizations: []
}

export default (state: BooksReducerState = initialState, action: Action<any>): BooksReducerState => {
  switch (action.type) {
    case actions.FETCH_ORGANIZATIONS_SUCCESS:
      return { ...state, organizations: action.payload }
    default:
      return state
  }
}
